import { InboxIcon } from "@heroicons/react/24/outline"
import { Link } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import Button from "../components/base/Button"
import Footer from "../components/base/Footer"
import Header from "../components/base/Header"
import Overlay from "../components/base/Overlay"
import SEO from "../components/base/seo"
import Clients from "../components/marketing/Clients"
import Featured from "../components/marketing/Featured"
import Features from "../components/marketing/Features"
import Hero from "../components/marketing/Hero"
import HeroVideo from "../components/marketing/HeroVideo"
import LeftContentCard from "../components/marketing/LeftContentCard"
import Mission from "../components/marketing/Mission"
import Pricing from "../components/marketing/Pricing"
import SubscribeAction from "../components/marketing/SubscribeAction"
import Whatsapp from "../components/marketing/Whatsapp"
import DownloadAndroid from "../images/download_android.png"
import DownloadIOS from "../images/download_ios.png"
import MockUp from "../images/home_mockup.png"
import DoorImage from "../images/home_ss14.webp"
import { updatePromotionCode } from "../store/actions/promotionActions"
import { Language } from "../store/reducers/utilsReducer"
import { localeContent } from "../utils/locale"

interface Props {
  authStore: any
  updatePromotionCode: (promoCode: string) => void
}

class Home extends Component<Props> {
  state = {
    visible: false,
    loading: false,
  }

  componentDidMount = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const promoCode = searchParams.get("promo")
    if (promoCode) {
      this.props.updatePromotionCode(promoCode)
    }
  }

  render() {
    const language = Language.EN
    return (
      <div className="bg-white">
        <Whatsapp />
        <SEO
          title="Arus Oil - Home"
          description="Arus Oil ensures environment sustainability with recycling used cooking oil. It allows users to start selling their used cooking oil seamlessly in one app."
        />
        <Overlay
          loading={this.state.loading}
          text={localeContent(language).overlay.text}
        />
        {/* <Banner text={localeContent(language).home.ecf} /> */}
        <Header page={"Index"} />
        <Hero
          title={localeContent(language).home.heroTitle}
          content={localeContent(language).home.heroDescription}
          image={MockUp}
          callToAction={
            <div className="mt-4 sm:mt-0">
              <Link to="/auth/signUp">
                <Button
                  className="w-full"
                  type="normal"
                  text={localeContent(language).signUp.text}
                />
              </Link>
              <a href="https://play.google.com/store/apps/details?id=com.arusoil.app">
                <img
                  src={DownloadAndroid}
                  className="h-12 w-44 mt-6 sm:mt-4 object-cover rounded"
                  alt="Play Store"
                />
              </a>
              <a href="https://apps.apple.com/us/app/arus-oil/id1584768580">
                <img
                  src={DownloadIOS}
                  className="h-12 w-44 mt-3 sm:mt-2 object-cover rounded"
                  alt="App Store"
                />
              </a>
            </div>
          }
          language={language}
        />
        <HeroVideo videoUrl="https://www.youtube.com/embed/fh8nOpUeaw0" />
        <Features language={language} />
        <LeftContentCard
          image={DoorImage}
          title={localeContent(language).leftContentCard.title}
          content={localeContent(language).leftContentCard.content}
          icon={<InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />}
          callToAction={
            <Link to="/auth/signUp">
              <Button
                text={localeContent(language).leftContentCard.button}
                type="normal"
              />
            </Link>
          }
        />
        {process.env.GATSBY_MEDIUM !== "brunei" && (
          <Pricing language={language} />
        )}
        <Mission language={language} />
        <Clients language={language} />
        <Featured language={language} />
        <SubscribeAction language={language} />
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updatePromotionCode: (promoCode: string) =>
      dispatch(updatePromotionCode(promoCode)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
